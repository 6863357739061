import {
        handleQueryResolve
      } from '../utils';
// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function () {
// FIXME - need to set this up to work with the query for the blister pack 
   

        return this.query(`
        SELECT "DBA"."Groups"."Id" as GroupID,
"DBA"."Groups"."Name" as Name,
"DBA"."Groups"."GroupStatusCode"
FROM "DBA"."Groups"
        `).then(handleQueryResolve);
    
}